import React from 'react';
import { flowRight } from 'lodash';
import { I18nextProvider } from 'react-i18next';
import { initI18n } from '@wix/communities-blog-client-common';

import { connect } from '../../../common/components/runtime-context';
import ResponsiveListener from '../../../common/components/responsive-listener/responsive-listener';
import { getLanguage } from '../../../common/store/basic-params/basic-params-selectors';
import { getTranslations } from '../../../common/store/translations/translations-selectors';
import { withReduxStore } from '../../../common/components/runtime-context/with-redux-store';
import { getSectionUrl } from '../../../common/store/topology/topology-selectors';
import AppLoaded from '../../../common/components/app-loaded';
import Archive from '../archive';
import ArchiveNew from '../archive/archive-new';
import { getIsArchiveWidgetAdapterEnabled } from '../../aggregated-archive/selectors';
import styles from './app-root.scss';

class AppRoot extends React.Component {
  getI18nConfig = (language, translations) => {
    if (this.lastLanguage !== language) {
      this.i18nConfig = initI18n(language, translations);
      this.lastLanguage = language;
    }
    return this.i18nConfig;
  };

  render() {
    if (this.props.isArchiveWidgetAdapterEnabled) {
      return (
        <ResponsiveListener>
          <div className={styles.baseStyles}>
            <ArchiveNew />
            <AppLoaded />
          </div>
        </ResponsiveListener>
      );
    }
    const { language, translations } = this.props;
    const i18n = this.getI18nConfig(language, translations);

    return (
      <ResponsiveListener>
        <I18nextProvider i18n={i18n}>
          <div className={styles.baseStyles}>
            <Archive />
            <AppLoaded />
          </div>
        </I18nextProvider>
      </ResponsiveListener>
    );
  }
}

const mapRuntimeToProps = (state) => {
  return {
    language: getLanguage(state),
    translations: getTranslations(state),
    sectionUrl: getSectionUrl(state),
    isArchiveWidgetAdapterEnabled: getIsArchiveWidgetAdapterEnabled(state),
  };
};

export default flowRight(withReduxStore, connect(mapRuntimeToProps))(AppRoot);
