import React from 'react';
import { isExperimentEnabled } from '@wix/communities-blog-client-common';
import { EXPERIMENT_FIX_RESIZE_ISSUE } from '@wix/communities-blog-experiments';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { getIsInIFrame } from '../selectors/is-in-iframe';
import { getHasReportedAppLoad } from '../store/app-loaded/app-loaded-selectors';
import { initWindowResize } from '../services/communities-window-resize';

export const withCommunitiesWindowResize = (WrappedComponent) => {
  class WithCommunitiesWindowResize extends React.Component {
    componentDidUpdate(prevProps) {
      if (!isExperimentEnabled(this.props.state, EXPERIMENT_FIX_RESIZE_ISSUE)) {
        return;
      }

      if (!getIsInIFrame(this.props.state)) {
        return;
      }

      const appLoaded = getHasReportedAppLoad(this.props.state);
      if (appLoaded && appLoaded !== getHasReportedAppLoad(prevProps.state)) {
        initWindowResize();
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  hoistNonReactStatics(WithCommunitiesWindowResize, WrappedComponent);

  return WithCommunitiesWindowResize;
};
